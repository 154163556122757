
import React, { useState, } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap'; // Import Bootstrap components
import Web3 from 'web3';
import FileInput from './Components/file';
import axios from 'axios'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Client } from "colyseus.js";


export let DEBUG = false

let server = DEBUG ? "http://localhost:2661/" : "https://dclstreams.com/media/"

const chunkSize = 1024 * 1024; // 1 MB chunk size
let fileToUpload

let totalSize  = 0
let offset = 0;

function App() {
  const [web3, setWeb3] = useState(null);
  const [loading, setLoading] = useState(true)
  const [newVideo, setNewVideo] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null);
  const [videoName, setVideoName] = useState("")
  const [account, setAccount] = useState("")
  const [status, setStatus] = useState("")
  const [videos, setVideos] = useState([])
  const [uploadProgress, setUploadProgress] = useState(0)
  const [cRoom, setRoom] = useState(null)
  const [type, setType] = useState('')

  // Listen for account changes
  const handleAccountChange = (accounts) => {
    console.log('account changed')
    setWeb3(null);
  };

  const handleVideoUpload = ()=>{
    setType("video")
    setNewVideo(true)
  }

  const handleImageUpload = ()=>{
    setType("image")
    setNewVideo(true)
  }

  const connectToMetaMask = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setAccount(accounts[0])
        console.log('Logged in:', accounts[0]);

        const web3Instance = new Web3(window.ethereum);
        window.ethereum.on('accountsChanged', handleAccountChange);
        window.ethereum.on('chainChanged', handleAccountChange);

        setWeb3(web3Instance);
        connectToColyseus(accounts[0]);
      } catch (error) {
        console.error(error);
      }
    } else {
      alert('MetaMask extension not detected!');
    }
  };

  const connectToColyseus = (mmaccount) => {
    const client = new Client(DEBUG ? "ws://localhost:2661" : "wss://dclstreams.com/media");

    client.joinOrCreate("video-room", {userId: mmaccount}).then(room => {
      console.log("joined room", room);

      setRoom(room)

      // room.onMessage("init", (info)=>{
      //   console.log('init info is', info)
      // })

      room.onMessage("assets-loaded", (info)=>{
        console.log('init info is', info)
        setVideos(info)
        setLoading(false)
      })

      room.onMessage("UPLOAD_START", (info)=>{
        console.log('starting upload')
        sendFileChunk(room)
      })

      room.onMessage("UPLOAD_CHUNK", (info)=>{
        sendFileChunk(room)
      })

      room.onLeave((info)=>{
        console.log('left colyseus', info)
      })

    }).catch(e => {
      console.error("join error", e);
    });
  };

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    console.log('file is', file)
    fileToUpload = file
  };

  const updateVideoName = (event) =>{
    setVideoName(event.target.value)
  }

  const deletVideo = (id) =>{
    cRoom.send('DELETE_VIDEO', id)
  }

  // Function to update progress bar
const updateProgress = (event) =>{
  if (event.lengthComputable) {
      const percentComplete = (event.loaded / event.total) * 100;
      // document.getElementById('progressBar').style.width = percentComplete + '%';
      // const percentCompleted = Math.round((event.loaded * 100) / event.total);
      console.log('onUploadProgress', percentComplete);
      setUploadProgress(percentComplete)
  }
}

const sendFileChunk = (room) =>{
  try{
    if(offset < totalSize) {
      const end = Math.min(offset + chunkSize, totalSize); // Ensure within bounds
      const chunk = fileToUpload.slice(offset, end); // Get the chunk
      const reader = new FileReader(); // Read file data

      reader.onload = (e) => {
          const arrayBuffer = e.target.result; // Read as ArrayBuffer
          room.sendBytes("UPLOAD", arrayBuffer); // Send chunk to Colyseus room
      };

      reader.readAsArrayBuffer(chunk); // Read the chunk
      offset += chunkSize; // Increment offset

      const percentage = Math.round((end / totalSize) * 100); // Calculate percentage
      setUploadProgress(percentage)

    }else{
          // Signal completion//
          room.send('UPLOAD_COMPLETE');
          offset = 0
          totalSize = 0
          setNewVideo(false)
          setStatus("success")
          setSelectedFile(null)
          setVideoName("")
    }
  }
  catch(e){
    console.log('web socket upload error', e)
    setStatus("failure")
  }
}

  const beginUpload = async()=>{
    if(selectedFile === null || videoName === ""){
      return
    }

    // if(selectedFile.size / chunkSize >= 100){
    //   console.log('file size too large')
    //   setStatus("toolarge")
    //   return
    // }

    setStatus('uploading')
  
    const fileName = selectedFile.name; // Get the full file name
    const fileExtension = fileName.split('.').pop(); // Get the extension
    totalSize =  selectedFile.size;
    cRoom.send('UPLOAD_START', {user:account, name:videoName, filename:fileName, ext: fileExtension, size:totalSize, type:type})
}


  function getLoading(){
    return(
      <div className='ui center'>                        
        <div className='ui loader active'/>
      </div>
    )
  }


  function getUserPage(){
    return(
      <div style={{backgroundColor:'black', minHeight:'100vh'}} className="Page-story-container">

      <div className="dcl page">

        <div className="ui container">

          {loading ? 

          getLoading()

          :

          newVideo ? 

          status === "uploading" ?

          showUploadModal()

          :

          getNewVideoModal()

          :


            <div className='ui story-container' style={{paddingTop:'20em'}}>
              <div className='ui center'>
              <Row>
                <Col>                        
                <div className='ui header'>Your Assets</div>
                </Col>

                <Col>
                <label className="ui button large primary block" onClick={()=>{handleVideoUpload()}}>Upload Video</label>
                  </Col>
                  <Col>
                <label className="ui button large primary block" onClick={()=>{handleImageUpload()}}>Upload Image</label>
                  </Col>
              </Row>

        <table className="ui very basic table">
  <thead className="">
    <tr className="">
      <th className="">Name</th>
      <th className="">Type</th>
      <th className="">Size</th>
      <th className="">Link</th>
      <th className="">Status</th>
      <th className="">Actions</th>
      </tr>
      </thead>
      <tbody className="">
        {videos.map((info, index)=> (
                <tr >
                <td className="closed" style={{color:'white'}}>
                  {info.name}
                  </td>
                  <td className="closed" style={{color:'white'}}>
                  {info.type}
                  </td>
                  <td className="closed" style={{color:'white'}}>
                    {(info.size / chunkSize).toFixed(2)} MB
                    </td>
                    <td className="closed" style={{color:'white'}}>
                      {
                        info.type === "image" ? 
                        <a href={server + "images/" + info.id + "." + info.ext} target='_blank'>Asset link</a>
                        : 
                        <a href={server + "videos/play/" + info.id + ".m3u8"} target='_blank'>Asset link</a>

                      }
                    </td>
                    <td className="closed" style={{color:'white'}}>
                      {info.status}
                    </td>
                    <td className="closed" style={{color:'white'}}>
                      {info.status === "READY" ? <a href={"#"} onClick={()=>{deletVideo(info.id)}}>Delete Asset</a> : <div></div>}
                    </td>
                  </tr>
        ))}

          </tbody>
        </table>
        </div>
        </div>
          }


        </div>

        </div>
        </div>
    )
  }

  function getNewVideoModal(){
    return( 
      <div className="ui page visible active" style={{display: "flex !important"}}>
      <div className="ui small visible active"><div className="dcl close ">
        <div className="close-icon" onClick={()=>{setNewVideo(false)}}></div></div>
        <div className="header">Upload New Video</div>
        <div className="content">
        <FileInput type={"Choose " + type} onFileSelect={handleFileSelect} /><br/>
        <span style={{color:"#ffffff"}}>{selectedFile && selectedFile.name}</span>
        <div>
          <br/>
        <div className="ui header"><label>{type} Name</label></div>
      <div className="ui input" style={{width:'100%'}}><input type="text" value={videoName} onChange={updateVideoName}/></div>
        </div>

        <div>
          <br/>
        <label className="ui button large primary sm" onClick={()=>{beginUpload()}}>Upload {type}
    </label><br></br>
        </div>

      {/* <p>{uploadProgress} %</p> */}
      </div>
        </div></div>
  
    )
  }

  function showUploadModal(){
    return( 
      <div className="ui page modals dimmer transition visible active" style={{display: "flex !important"}}>
      <div className="ui small modal transition visible active"><div className="dcl close ">
        <div className="close-icon" onClick={()=>{setNewVideo(false)}}></div></div>
        <div className="header">Uploading {type}...</div>
        <div>
           <ProgressBar now={uploadProgress} />
           <div className="content"><p>{uploadProgress} %</p></div>
        </div>
        </div>
        </div>
  
    )
  }


  return (
    <div className=" ui page">
      {web3 ? 
      getUserPage()
       
      :

      <button className='ui button' onClick={connectToMetaMask}>Connect to MetaMask</button>
  }
    </div>
  );
}

export default App;
